import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable()
export class PassfeetService {
  private readonly http = inject(HttpClient);

  getQRCode(): Observable<string> {
    return this.http.request('GET', `${environment.apiUrl}/passfeet/qrcode`, { responseType: 'text'});
  }
}
